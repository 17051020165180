._loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

._loader #spinner {
  box-sizing: border-box;
  stroke: var(--main_color);
  stroke-width: 2.5px;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
  animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

:root {
  --main_color: #007AFF;
  --body_color: #1E1E1E;
  --body_color_alt: #161616;
  --border: #8c8c8c20;
  --link: #007AFF;
}

body {
  margin: 0px;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
}

a {
  text-decoration: none;
}

._page {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.menu {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  top: 0px;
  z-index: 9;
  height: 64px;
  background: #ffffff;
  border-bottom: 1px solid gainsboro;
}

.menu .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  text-align: center;
  transition: 0.1s ease-in;
  border-right: 1px solid gainsboro;
}

.menu .item:hover {
  opacity: 0.5;
}

.title {
  font-size: 21px;
  font-weight: bold;
}

.inner {
  display: flex;
  flex-direction: column;
}

.report-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.report-list .title {
  margin-bottom: 18px;
}

.group {
  display: flex;
  align-items: start;
  width: 100%;
}

.group .section {
  margin-right: 18px;
}

.group .section:last-child {
  margin-right: 0px;
}

.report-create .title {
  margin-bottom: 18px;
  margin-top: 18px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
  background: #ffffff;
}

tr:nth-child(even) {
  background: #c8c8c8 !important;
}

.report-list .search {
  display: flex;
  margin-bottom: 18px;
}

.button {
  padding: 16px 32px;
  background: rgba(0, 0, 0, 0.683);
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
  transition: 0.1s ease-in;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

.button:hover {
  opacity: 0.7;
}

.info {
  padding: 8px;
  background: rgb(97, 186, 246);
  border-radius: 4px;
  margin-bottom: 24px;
  color: #fff;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  width: 100%;
}

.section .label {
  margin-bottom: 8px;
}

.report-create .add-passenger {
  background: orange;
  margin-top: 12px;
}

input {
  border: 1px solid gainsboro;
  padding: 18px;
  font-size: 16px;
  border-radius: 8px;
}

.account-auth {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.account-create {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.link {
  background: #006eff;
}

.error {
  margin-top: 14px;
  color: #ff2e2e;
}

.report-create .delete {
  background: #ff2e2e;
  margin-top: 7px;
}

.search-select-box {
  width: 100%;
  position: relative;
}

.search-select-box input {
  width: 100%;
}

.search-select-box .dropdown {
  margin-top: 8px;
  width: 100%;
  max-height: 192px;
  overflow: scroll;
  background: #fff;
  border-radius: 4px;
  border: 1px solid gainsboro;
  z-index: 999;
}

.search-select-box .dropdown .dropdown-item {
  cursor: pointer;
  padding: 16px;
  font-size: 16px;
  border-bottom: 1px solid #00000010;
}

.search-select-box .dropdown .dropdown-item:nth-child(even) {}

.search-select-box .dropdown .dropdown-item:hover {
  background: #d1d1d1;
}

.search-select-box .dropdown .dropdown-item .leader {
  margin-right: 18px;
  font-weight: bold;
}

.driver-create {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.driver-create .title {
  margin-bottom: 18px;
}

.driver-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pdf-download {
  color: #ffffff;
  cursor: pointer;
  background: #006eff;
  text-align: center;
}

.driver-list .title {
  margin-bottom: 18px;
}

@media only screen and (max-width: 600px) {
  .group {
    flex-direction: column;
  }

  .group .section {
    margin-right: 0px !important;
  }
}

.date-filter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-filter {
  display: flex;
  align-items: center;
}

.date-filter .date {
  display: flex;
  flex-direction: column;
  margin-right: 18px;
}

.date-filter .date .date-title {
  margin-bottom: 8px;
}

.date-filter .search {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 96px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background: #006eff;
  color: #ffffff;
}

.date-filter .search:disabled {
  background: #61a5ff;
}

.date-filter .search:hover {
  background: #0050ba;

}

.list-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

._page_home {
  display: flex;
  flex-direction: column;
}

._page_home .contact {
  display: flex;
  flex-direction: column;
}

._page_home .logout {
  display: flex;
  background: red;
  padding: 12px;
  width: fit-content;
  color: #ffffff;
  cursor: pointer;
}

._page_auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-top: 0px;
  max-width: 356px;
}

._page_report_create .passenger {
  display: flex;
  flex-direction: column;
}

._page_report_create .passenger .passenger_title {
  font-weight: bold;
  font-size: 18px;
}

.toast {
  z-index: 9999;
}